<template>
  <div class="central-outer-container">
    <div class="central-container">
      <p class="central-description">{{ $rt(getKeys.description) }}</p>
      <div class="central-box">
        <div v-for="(key, index) in getKeys.pages" :key="index" class="central-item-box" @mouseover="handleHover(index)"
          @mouseleave="handleLeave(index)">
          <LocLink class="central-item-link" :to="nuxt._route.fullPath + '/' + key.path">
            <div :style="'background-image: url(' + key.imageUri + ')'" class="central-box-image"></div>
            <div style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              ">
              <h3 class="central-item-title">{{ key.title }}</h3>
              <BaseArrow :ref="'arrow' + index" color="rgb(147, 147, 147)" hoverColor="#ff0000" />
            </div>
          </LocLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseArrow from '~/components/base/BaseArrow.vue';
import LocLink from './LocLink.vue';

export default {
  data () {
    return {
      isArrow: false,
    };
  },
  setup () {
    const nuxt = useNuxtApp();
    return {
      nuxt,
    };
  },
  props: ['keys'],
  components: {
    BaseArrow, LocLink
  },
  computed: {
    getKeys () {
      return this.$props.keys;
    },
  },
  methods: {
    handleHover (index) {
      this.$refs[('arrow' + index)][0].handleOver();
    },
    handleLeave (index) {
      this.$refs[('arrow' + index)][0].handleOut();
    }
  },
};
</script>

<style>



</style>

<style scoped>


.central-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
}
.central-banner {
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.central-topic {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.8em;
  font-size: 1rem;
  color: inherit;
  display: block;
}
.central-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 50px 0;
  width: 100%;
  gap: 2rem;
}
.central-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}
.central-box-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 60%;
  background-color: #ccc;
}
.central-item-box {
  margin-bottom: 2%;
  background: #f7f6f1;
  padding: 5px;
  display: block;
  text-decoration: none;
}
.central-item-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
  padding: 20px 10px;
}
.central-item-link {
  text-decoration: none;
}
.central-arrow {
  fill: #f48d44;
}
.central-description {
  font-size: 25px;
  max-width: 700px;
  line-height: 1.2em;
  margin-top: 1em;
}
@media only screen and (max-width: 950px) {
  .central-box {
    grid-template-columns: 1fr 1fr;
  }

}
@media only screen and (max-width: 700px) {
  .central-box {
    grid-template-columns: 1fr;
  }
}
</style>
